/**
 * Scene
 * @constructor
 */
class Scene {
  constructor() {}
  /**
   * Scene
   */
  init() {
    
    $(window).on('load',function(){
      var path = location.pathname
      if (path == "/scene/narrow/") {
        $('.narrow a').addClass('btn-bg');
      } else if (path == "/scene/skyrestriction/") {
        $('.skyrestriction a').addClass('btn-bg');
      } else if (path == "/scene/wrecker_loading/") {
        $('.wrecker_loading a').addClass('btn-bg');
      } else if (path == "/scene/slope_construction/") {
        $('.slope_construction a').addClass('btn-bg');
      } else if (path == "/scene/support_ground_unevenness/") {
        $('.support_ground_unevenness a').addClass('btn-bg');
      } else if (path == "/scene/cobblestonemudstone/") {
        $('.cobblestonemudstone a').addClass('btn-bg');
      } else if (path == "/scene/soft_ground/") {
        $('.soft_ground a').addClass('btn-bg');
      } else if (path == "/scene/without_core/") {
        $('.without_core a').addClass('btn-bg');
      } else if (path == "/scene/without_slabcore/") {
        $('.without_slabcore a').addClass('btn-bg');
      } else if (path == "/scene/pile_removal/") {
        $('.pile_removal a').addClass('btn-bg');
      } else if (path == "/scene/railway_sector/") {
        $('.railway_sector a').addClass('btn-bg');
      }
    });

  }
};

export default Scene;
