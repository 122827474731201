/**
 * import Vue
 * ※vueを使用しない場合は削除する
 */
//import App from './vue/App.js';

/**
 * import node_modules
 */
import 'desvg';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import SmoothScroll from 'smooth-scroll';


/**
 * import modules
 */
import Header_menu from './modules/header_menu';
import Top_tabChange from './modules/top_tabChange';
import Top_svgAnimation from './modules/top_svgAnimation';
import Top_lineup from './modules/top_lineup';
import Top_header_hover from './modules/top_header_hover';
import Scene from './modules/scene';
import Method from './modules/method';
import Pagetop from './modules/pagetop';
import Smoothscroll from './modules/smoothscroll';
import Common from './modules/common';
import Faq from './modules/faq';
import Entry from './modules/entry';


const target = $('main').attr('id');
switch (target) {
  case 'top':
    console.log(target)
    /* TOP ロゴ切り替え */
    const top_header_hover = new Top_header_hover();
    top_header_hover.init();
    /* kv svgアニメーション */
    const top_svgAnimation = new Top_svgAnimation();
    top_svgAnimation.init();
    /* TOP lineup */
    const top_lineup = new Top_lineup();
    top_lineup.init();
    /* TOP タブ切り替え */
    const top_tabChange = new Top_tabChange();
    top_tabChange.init();
    break;

  case 'scene':
    console.log(target)
    const scene = new Scene();
    scene.init();
    break;

  case 'method':
    console.log(target)
    const method = new Method();
    method.init();
    break;

  case 'faq':
    console.log(target)
    const faq = new Faq();
    faq.init();
    break;

  case 'entry':
    console.log(target)
    const entry = new Entry();
    entry.init();
    break;
}

/**
 * Mainクラス
 * @constructor
 */
class Main {
  constructor() {
    this.setup();
  }
  /**
   * インスタンス生成時に実行する処理
   */
  setup() {
    document.addEventListener('DOMContentLoaded', () => {
      this.created();

    })
    window.addEventListener('load', () => {
      this.mounted();
    });
  }
  /**
   * ページ読み込み時に実行する処理
   */
  created() {
    picturefill();

    /* PCナビ */
    const header_menu = new Header_menu();
    header_menu.init();

    /* pagetop */
    const smoothscroll = new Smoothscroll();
    smoothscroll.init();

    /* pagetop */
    const pagetop = new Pagetop();
    pagetop.init();

    /* 共通 */
    const common = new Common();
    common.init();

  }
  /**
   * 画像読み込み後に実行する処理
   */
  mounted() {
    this.init_deSVG('.js-desvg');
    this.init_objectFitImages('.object-fit');
    this.init_smoothScroll();
  }

  /**
   * ライブラリ/deSVG
   * インラインsvgをsvgタグに変換
   * https://github.com/benhowdle89/deSVG
   * @param {Element} target ターゲットとなる要素
   */
  init_deSVG(target) {
    deSVG(target, true)
  }

  /**
   * ライブラリ/object-fit-images
   * css object-fit のポリフィル
   * https://github.com/fregante/object-fit-images
   * @param {Element} target ターゲットとなる要素
   */
  init_objectFitImages(target) {
    objectFitImages(target);
  }

  /**
   * ライブラリ/picturefill
   * html pictureタグのポリフィル
   * https://scottjehl.github.io/picturefill/
   */
  init_picturefill() {
    picturefill();
  }

  /**
   * ライブラリ/smooth-scroll
   * スムーススクロール
   * https://github.com/cferdinandi/smooth-scroll
   */
  init_smoothScroll() {
    /**
     * ターゲットとなる要素
     * @type {Element}
     */
    const targetElement = 'a[href*="#"]';

    /**
     * オプション
     * @type {object}
     */
    const option = {
      speed: 1000,
      easing: 'easeInOutCubic',
      speedAsDuration: true,
      //header: '.l-header' 固定ヘッダーの場合
    }

    new SmoothScroll(targetElement, option);
  }
}

export default new Main();
