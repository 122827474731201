import Swiper from "swiper";
import Vivus from "vivus";
import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

/**
 * Top_animeScroll
 * @constructor
 */
class Top_animeScroll {
  constructor() {}
  /**
   * Top_animeScroll
   */
  init() {

    var pc = window.matchMedia("(min-width: 751px)").matches;
    var sp = window.matchMedia("(max-width: 750px)").matches;
    
    $(function () {
      var webStorage = function () {
        if (sessionStorage.getItem('access')) {/* 2回目以降アクセス時の処理 */
          const kv = new TimelineMax();
          kv.to('.p-topSlider__overlay', 0, {
            display: 'none',
          })
          .to('.p-topSlider__logo', 0, {
            zIndex: '2',
          })
          .to('.p-topSlider__bg', 0, {
            opacity: '1',
            onComplete: function () {
              new Vivus('svg-animation-bg', {
                type: 'sync',
                start: 'autostart',
                duration: 50,
                forceRender: false,
                animTimingFunction: Vivus.EASE
              });
            }
          }, '-=0.7')
          .to('.p-topSlider__logoTxt', 0.5, {
            opacity: '1',
            ease: Power3.easeIn,
            onComplete: function () {
              new Swiper('.swiper-container', {
                loop: true,
                effect: 'fade',
                autoplay: {
                  delay: 4000,
                  disableOnInteraction: false,
                },
                speed: 2000,
              })
            }
          }, '+=0.3')
        } else {/* 初回アクセス時の処理 */
          sessionStorage.setItem('access', 'true'); // sessionStorageにデータを保存
          var userAgent = window.navigator.userAgent.toLowerCase();
          if (userAgent.indexOf('msie') != -1) {
            console.log()
          } else {
            console.log()
            const kv = new TimelineMax();
            kv.to('.p-topSlider__logoInner', 0, {
              onComplete: function () {
                new Vivus('svg-animation', {
                  type: 'scenario-sync',
                  start: 'autostart',
                  duration: 10,
                  forceRender: false,
                  animTimingFunction: Vivus.EASE
                });
              }
            }, '+=1.0')
            .to('.p-topSlider__logoInner', 1.0, {
              opacity: '0'
            }, '+=2.5')
            .to('.p-topSlider__overlay', 1.5, {
              opacity: '0',
              ease: Power3.easeIn,
            }, '-=1.5')
            .to('.p-topSlider__overlay', 0, {
              display: 'none',
            })
            .to('.p-topSlider__logo', 0, {
              zIndex: '2',
            })
            .to('.p-topSlider__bg', 0, {
              opacity: '1',
              onComplete: function () {
                new Vivus('svg-animation-bg', {
                  type: 'sync',
                  start: 'autostart',
                  duration: 50,
                  forceRender: false,
                  animTimingFunction: Vivus.EASE
                });
              }
            }, '-=0.7')
            .to('.p-topSlider__logoTxt', 0.5, {
              opacity: '1',
              ease: Power3.easeIn,
              onComplete: function () {
                new Swiper('.swiper-container', {
                  loop: true,
                  effect: 'fade',
                  autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                  },
                  speed: 2000,
                })
              }
            }, '+=0.3')
          }
        }
      }
      webStorage();
    });
    
    
//  var userAgent = window.navigator.userAgent.toLowerCase();
//  if (userAgent.indexOf('msie') != -1) {
//    console.log()
//  } else {
//    console.log()
//    const kv = new TimelineMax();
//    kv.to('.p-topSlider__logoInner', 0, {
//        onComplete: function () {
//          new Vivus('svg-animation', {
//            type: 'scenario-sync',
//            start: 'autostart',
//            duration: 10,
//            forceRender: false,
//            animTimingFunction: Vivus.EASE
//          });
//        }
//      }, '+=1.0')
//      .to('.p-topSlider__logoInner', 1.0, {
//        opacity: '0'
//      }, '+=2.5')
//      .to('.p-topSlider__overlay', 1.5, {
//        opacity: '0',
//        ease: Power3.easeIn,
//      }, '-=1.5')
//      .to('.p-topSlider__overlay', 0, {
//        display: 'none',
//      })
//      .to('.p-topSlider__logo', 0, {
//        zIndex: '2',
//      })
//      .to('.p-topSlider__bg', 0, {
//        opacity: '1',
//        onComplete: function () {
//          new Vivus('svg-animation-bg', {
//            type: 'sync',
//            start: 'autostart',
//            duration: 50,
//            forceRender: false,
//            animTimingFunction: Vivus.EASE
//          });
//        }
//      }, '-=0.7')
//      .to('.p-topSlider__logoTxt', 0.5, {
//        opacity: '1',
//        ease: Power3.easeIn,
//        onComplete: function () {
//          new Swiper('.swiper-container', {
//            loop: true,
//            effect: 'fade',
//            autoplay: {
//              delay: 4000,
//              disableOnInteraction: false,
//            },
//            speed: 2000,
//          })
//        }
//      }, '+=0.3')
//    }
  }
};
export default Top_animeScroll;