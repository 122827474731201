import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

/**
 * Pagetop
 * @constructor
 */
class Pagetop {
  constructor() {}
  /**
   * Pagetop
   */
  init() {
    var topBtn = $('#pagetop');
    topBtn.hide();
    $(window).scroll(function () {
      if ($(this).scrollTop() > 200) {
        topBtn.fadeIn();
      } else {
        topBtn.fadeOut();
      }
    });
    topBtn.click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 500);
      return false;
    });

    /* pagetop */
    const pagetop = new TimelineMax();
    new ScrollMagic.Scene({
        triggerElement: '.l-footer',
        triggerHook: 'onEnter',
        offset: 0,
        duration: 0,
        reverse: true,
      })
      .setTween(pagetop)
      .setClassToggle('.pagetop', 'abso')
//    .addIndicators({name: 'pagetop'})
      .addTo(controller);
  }
};

export default Pagetop;
