/**
 * Top_lineup
 * @constructor
 */
class Top_lineup {
  constructor() {}
  /**
   * Top_lineup
   */
  init() {

    var pc = window.matchMedia("(min-width: 751px)").matches;
    var sp = window.matchMedia("(max-width: 750px)").matches;

    if (pc) {
      $('.p-topAbout__listItem--01').on({
        'mouseenter': function () {
          $('.p-topAbout__list').css('background', 'url(/assets/img/kv_slider01.png)');
          $('.p-topAbout__list').css('background-size', 'cover');
        },
        'mouseleave': function () {
          //                    $('.p-topAbout__list').css('background', '#000');
        }
      });

      $('.p-topAbout__listItem--02').on({
        'mouseenter': function () {
          $('.p-topAbout__list').css('background', 'url(/assets/img/kv_slider02.png)');
          $('.p-topAbout__list').css('background-size', 'cover');
        },
        'mouseleave': function () {
          //                    $('.p-topAbout__list').css('background', '#000');
        }
      });

      $('.p-topAbout__listItem--03').on({
        'mouseenter': function () {
          $('.p-topAbout__list').css('background', 'url(/assets/img/kv_slider01.png)');
          $('.p-topAbout__list').css('background-size', 'cover');
        },
        'mouseleave': function () {
          //                    $('.p-topAbout__list').css('background', '#000');
        }
      });

      $('.p-topAbout__listItem--04').on({
        'mouseenter': function () {
          $('.p-topAbout__list').css('background', 'url(/assets/img/kv_slider02.png)');
          $('.p-topAbout__list').css('background-size', 'cover');
        },
        'mouseleave': function () {
          //                    $('.p-topAbout__list').css('background', '#000');
        }
      });

      $('.p-topAbout__listItem--05').on({
        'mouseenter': function () {
          $('.p-topAbout__list').css('background', 'url(/assets/img/kv_slider01.png)');
          $('.p-topAbout__list').css('background-size', 'cover');
        },
        'mouseleave': function () {
          //                    $('.p-topAbout__list').css('background', '#000');
        }
      });

      $('.p-topAbout__listItem--06').on({
        'mouseenter': function () {
          $('.p-topAbout__list').css('background', 'url(/assets/img/kv_slider02.png)');
          $('.p-topAbout__list').css('background-size', 'cover');
        },
        'mouseleave': function () {
          //                    $('.p-topAbout__list').css('background', '#000');
        }
      });

      $('.p-topAbout__listItem--07').on({
        'mouseenter': function () {
          $('.p-topAbout__list').css('background', 'url(/assets/img/kv_slider01.png)');
          $('.p-topAbout__list').css('background-size', 'cover');
        },
        'mouseleave': function () {
          //                    $('.p-topAbout__list').css('background', '#000');
        }
      });

      $('.p-topAbout__listItem--08').on({
        'mouseenter': function () {
          $('.p-topAbout__list').css('background', 'url(/assets/img/kv_slider02.png)');
          $('.p-topAbout__list').css('background-size', 'cover');
        },
        'mouseleave': function () {
          //                    $('.p-topAbout__list').css('background', '#000');
        }
      });

      $('.p-topAbout__listItem--09').on({
        'mouseenter': function () {
          $('.p-topAbout__list').css('background', 'url(/assets/img/kv_slider01.png)');
          $('.p-topAbout__list').css('background-size', 'cover');
        },
        'mouseleave': function () {
          //                    $('.p-topAbout__list').css('background', '#000');
        }
      });
    }
  }
};

export default Top_lineup;
