import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

/**
 * Header_menu
 * @constructor
 */
class Header_menu {
  constructor() {}
  /**
   * Header_menu
   */
  init() {

    var pc = window.matchMedia("(min-width: 751px)").matches;
    var sp = window.matchMedia("(max-width: 750px)").matches;

    if (pc) {
      $('.l-header__listBottomItem--02,.menu-company').on({
        'mouseenter': function () {
          $('.menu-company').stop().fadeIn(300);
        },
        'mouseleave': function () {
          $('.menu-company').stop().fadeOut(300);
        }
      });
      $('.l-header__listBottomItem--03,.menu-method').on({
        'mouseenter': function () {
          $('.menu-method').stop().fadeIn(300);
        },
        'mouseleave': function () {
          $('.menu-method').stop().fadeOut(300);
        }
      });
      $('.l-header__listBottomItem--04,.menu-machines').on({
        'mouseenter': function () {
          $('.menu-machines').stop().fadeIn(300);
        },
        'mouseleave': function () {
          $('.menu-machines').stop().fadeOut(300);
        }
      });
      $('.l-header__listBottomItem--06,.menu-jobs').on({
        'mouseenter': function () {
          $('.menu-jobs').stop().fadeIn(300);
        },
        'mouseleave': function () {
          $('.menu-jobs').stop().fadeOut(300);
        }
      });
    }

    const header_menu = new TimelineMax();
    header_menu.to('.l-header', 0.3, {})
    new ScrollMagic.Scene({
        triggerElement: 'main',
        triggerHook: 'onLeave',
        offset: 150,
        duration: 0,
        reverse: true,
      })
      .setTween(header_menu)
      .setClassToggle('.l-header', 'active')
//      .addIndicators({name: "header_menu"})
      .addTo(controller);
    
    const spheader_menu = new TimelineMax();
    spheader_menu.to('.l-header', 0.3, {})
    new ScrollMagic.Scene({
        triggerElement: 'main',
        triggerHook: 'onLeave',
        offset: 150,
        duration: 0,
        reverse: true,
      })
      .setTween(spheader_menu)
      .setClassToggle('.l-spHeader', 'active')
//      .addIndicators({name: "spheader_menu"})
      .addTo(controller);
    
    $('.l-spHeader__btn').on('click', function () {
      $(this).toggleClass('is-active');
      $('.l-spHeader__ttl').fadeToggle(300);
      $('.l-spHeader__menu').stop().fadeToggle(300);
      if($(this).hasClass('is-active')) {
        $('.l-main').css('position','fixed')
        $('.l-spHeader').addClass('open');
      } else {
        $('.l-main').css('position','relative')
        $('.l-spHeader').removeClass('open');
      }
    })
    
    $('.js-toggle').on('click', function () {
      $(this).toggleClass('is-toggleOpen');
      $(this).next().stop().slideToggle(300);
    })

  }
};

export default Header_menu;
