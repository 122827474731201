import Modaal from "modaal";

/**
 * Top_animeScroll
 * @constructor
 */
class Top_animeScroll {
  constructor() {}
  /**
   * Top_animeScroll
   */
  init() {
    $('.modal_video').modaal();
  }
};
export default Top_animeScroll;