/**
 * Top_header_hover
 * @constructor
 */
class Top_header_hover {
  constructor() {}
  /**
   * Top_header_hover
   */
  init() {

    var pc = window.matchMedia("(min-width: 751px)").matches;
    var sp = window.matchMedia("(max-width: 750px)").matches;

    if (pc) {
      $('.l-header').on({
        'mouseenter': function () {
          $('.l-header').addClass('hover');
        },
        'mouseleave': function () {
          $('.l-header').removeClass('hover');
        }
      });
    }
  }
};

export default Top_header_hover;
