/**
 * Top_tabChange
 * @constructor
 */
class Top_tabChange {
  constructor() {}
  /**
   * Top_tabChange
   */
  init() {
    $('.p-topMachine__tabBtn').click(function () {
      var index = $('.p-topMachine__tabBtn').index(this);
      $('.p-topMachine__tabContents').css('display', 'none');
      $('.p-topMachine__tabContents').eq(index).css('display', 'block');
      $('.p-topMachine__tabBtn').removeClass('active');
      $(this).addClass('active')
    });
  }
};

export default Top_tabChange;
