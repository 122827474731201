import Modaal from "modaal";
/**
 * Method
 * @constructor
 */
class Method {
  constructor() {}
  /**
   * Method
   */
  init() {
    
    $(window).on('load',function(){
      var path = location.pathname
      if (path == "/method/eazet_features/") {
        $('.eazet-features a').addClass('btn-bg');
      } else if (path == "/method/eazet_specification/") {
        $('.eazet-specification a').addClass('btn-bg');
      } else if (path == "/method/eazet_design/") {
        $('.eazet-design a').addClass('btn-bg');
      } else if (path == "/method/eazet_pull/") {
        $('.eazet-pull a').addClass('btn-bg');
      } else if (path == "/method/eazet_fitting/") {
        $('.eazet-fitting a').addClass('btn-bg');
      } else if (path == "/method/eazet_about/") {
        $('.eazet-about a').addClass('btn-bg');
      }
    });
    
    /* ATTコラム */
    $(window).on('load',function(){
      var path = location.pathname
      if (path == "/method/attcolumn_features/") {
        $('.att-features a').addClass('btn-bg');
      } else if (path == "/method/attcolumn_specification/") {
        $('.att-specification a').addClass('btn-bg');
      } else if (path == "/method/attcolumn_vertical/") {
        $('.att-vertical a').addClass('btn-bg');
      } else if (path == "/method/attcolumn_pull/") {
        $('.att-pull a').addClass('btn-bg');
      } else if (path == "/method/attcolumn_horizontal/") {
        $('.att-horizontal a').addClass('btn-bg');
      } else if (path == "/method/attcolumn_process/") {
        $('.att-process a').addClass('btn-bg');
      } else if (path == "/method/attcolumn_machine/") {
        $('.att-machine a').addClass('btn-bg');
      }
    });
    
    /* テノコラム */
    $(window).on('load',function(){
      var path = location.pathname
      if (path == "/method/tenocolumn_features/") {
        $('.teno-features a').addClass('btn-bg');
      } else if (path == "/method/tenocolumn_machine_process/") {
        $('.teno-process a').addClass('btn-bg');
      }
    });
    
    /* HiFB */
    $(window).on('load',function(){
      var path = location.pathname
      if (path == "/method/hifb_features/") {
        $('.hifb-features a').addClass('btn-bg');
      } else if (path == "/method/hifb_design/") {
        $('.hifb-design a').addClass('btn-bg');
      } else if (path == "/method/hifb_specification/") {
        $('.hifb-specification a').addClass('btn-bg');
      } else if (path == "/method/hifb_process/") {
        $('.hifb-process a').addClass('btn-bg');
      }
    });
    
    /* ハイパーメガ */
    $(window).on('load',function(){
      var path = location.pathname
      if (path == "/method/hypermega_features/") {
        $('.hypermega-features a').addClass('btn-bg');
      } else if (path == "/method/hypermega_specification/") {
        $('.hypermega-specification a').addClass('btn-bg');
      } else if (path == "/method/hypermega_vertical/") {
        $('.hypermega-vertical a').addClass('btn-bg');
      } else if (path == "/method/hypermega_pull/") {
        $('.hypermega-pull a').addClass('btn-bg');
      } else if (path == "/method/hypermega_process/") {
        $('.hypermega-process a').addClass('btn-bg');
      }
    });
    
    /* ハイパーストレート */
    $(window).on('load',function(){
      var path = location.pathname
      if (path == "/method/hyperstraight_features/") {
        $('.hyperstraight-features a').addClass('btn-bg');
      } else if (path == "/method/hyperstraight_design1/") {
        $('.hyperstraight-design1 a').addClass('btn-bg');
      } else if (path == "/method/hyperstraight_design2/") {
        $('.hyperstraight-design2 a').addClass('btn-bg');
      } else if (path == "/method/hyperstraight_process/") {
        $('.hyperstraight-process a').addClass('btn-bg');
      }
    });
    
    /* NEWスーパーFK */
    $(window).on('load',function(){
      var path = location.pathname
      if (path == "/method/newsuperfk_features/") {
        $('.newsuperfk-features a').addClass('btn-bg');
      } else if (path == "/method/newsuperfk_design/") {
        $('.newsuperfk-design a').addClass('btn-bg');
      } else if (path == "/method/newsuperfk_outline/") {
        $('.newsuperfk-outline a').addClass('btn-bg');
      } else if (path == "/method/newsuperfk_process/") {
        $('.newsuperfk-process a').addClass('btn-bg');
      }
    });
    
    /* PC壁体 */
    $(window).on('load',function(){
      var path = location.pathname
      if (path == "/method/pcwall_features/") {
        $('.pcwall-features a').addClass('btn-bg');
      } else if (path == "/method/pcwall_specification/") {
        $('.pcwall-specification a').addClass('btn-bg');
      } else if (path == "/method/pcwall_technique/") {
        $('.pcwall-technique a').addClass('btn-bg');
      }
    });
    
    $('.modal_01').modaal();
    $('.modal_02').modaal();
    $('.modal_03').modaal();
    $('.modal_04').modaal();
    $('.modal_05').modaal();
    
  }
};

export default Method;
