/**
 * Faq
 * @constructor
 */
class Faq {
  constructor() {}
  /**
   * Faq
   */
  init() {
    
    $('.p-faq__block--jsClick').on('click', function () {
      $(this).next().stop().slideToggle(300);
      $(this).toggleClass('is-active');
    })
    
  }
};

export default Faq;
